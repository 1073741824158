import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

import AuthContext from "../../context/AuthContext";
import UserNotifyColumn from "./UserNotifyColumn";
import UserNotifyItem from "./UserNotifyItem";
import UserNotifyItemNotified from "./UserNotifyItemNotified";

export default function UserNotify() {
  const { token } = useContext(AuthContext);

  const [usersToNotify, setUsersToNotify] = useState([]);
  const [usersNotified, setUsersNotified] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (!token) return;
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/user/updatedNotifs", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => setUsersToNotify(res.data))
      .catch((err) => setErrMsg(err.message))
      .finally(() => setIsLoading(false));
  }, [token]);

  async function notifyUser(user) {
    const originalUsers = [...usersToNotify];
    setUsersToNotify(usersToNotify.filter((u) => u._id !== user._id));
    axios
      .patch(
        process.env.REACT_APP_BACKEND_URL + "/user/notify/" + user._id,
        {},
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => setUsersNotified([...usersNotified, res.data]))
      .catch((err) => {
        setUsersToNotify(originalUsers);
        setErrMsg(err.message);
      });
  }

  return (
    <div className="container-fluid">
      <h1 className="text-center">Manage User Notifications</h1>
      {isLoading && (
        <div className="d-flex justify-content-center text-primary">
          <div className="spinner-border"></div>{" "}
          <h5> ... Loading Notification Data</h5>
        </div>
      )}
      {errMsg && <div className="alert alert-danger">Error: {errMsg}</div>}
      <div className="container-fluid bg-light text-dark">
        <UserNotifyColumn />
        {usersNotified.length > 0 &&
          usersNotified.map((user, index) => (
            <UserNotifyItemNotified
              key={user._id}
              index={index}
              user={user}
              notifyUser={notifyUser}
            />
          ))}
        {usersToNotify.map((user, index) => (
          <UserNotifyItem
            key={user._id}
            index={index}
            user={user}
            notifyUser={notifyUser}
          />
        ))}
      </div>
    </div>
  );
}
