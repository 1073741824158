import { MdNotificationsActive } from "react-icons/md";

import UserManageItemModal from "../UserManage/UserManageItemModal";

export default function UserNotifyItem({ index, user, notifyUser }) {
  return (
    <div className={"row " + (index % 2 ? "mt-1" : "mt-1 bg-white")}>
      <div className="col-1">
        <UserManageItemModal
          user_id={user._id}
          userFName={user.fname}
          userLName={user.lname}
        />
      </div>
      <div className="col-1">{user.lname}</div>
      <div className="col-2">{user.phone}</div>
      <div className="col-2">{user.email}</div>
      <div className="col-1">
        {new Date(user.lastApptDate).toLocaleDateString()}
      </div>
      {/* <div className="col-1">{user.role}</div> */}
      <div className="col-1">
        {new Date(user.notifyDate).toLocaleDateString()}
      </div>
      <div className="col-1">
        <button className="btn btn-link" onClick={() => notifyUser(user)}>
          <MdNotificationsActive />
        </button>
      </div>
      <div className="col-1">{user.status}</div>
    </div>
  );
}
