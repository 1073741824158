import React from "react";

export default function UserNotifyColumn() {
  return (
    <div className="row bg-primary text-white font-weight-bold">
      <div className="col-1">FirstName*</div>
      <div className="col-1">LastName*</div>
      <div className="col-2">phone*</div>
      <div className="col-2">email</div>
      <div className="col-1">LastAppt</div>
      {/* <div className="col-1">role</div> */}
      <div className="col-1">NotifyDate</div>
      <div className="col-1">Notify</div>
      <div className="col-1">status</div>
    </div>
  );
}
